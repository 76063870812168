import { SelectPicker, Button, Input, Drawer, Checkbox } from "rsuite";
import { useEffect, useMemo } from "react";
import { useState } from "react";
import { Constants, Environment, EnvironmentName } from "./constants";
import { HexColorInput, HexColorPicker } from "react-colorful";
import { Toggle } from "@metronome-industries/design-system";

export interface Customer {
  id: string;
  name: string;
}

export interface Settings {
  apiToken: string;
  customerId: string;
  dashboardsHostOverride?: URL;
  colorOverrides: Record<string, string>;
  isDarkMode: boolean;
  bmGroupKeyOverrides: string;
  environmentName: EnvironmentName;
  environment: Environment;
  dashboardOptions: { key: string; value: string }[];
  clientPreset: string;
  iframeBackgroundColor?: string;
}

interface Props {
  showDialog: boolean;
  onClose: () => void;
  onChange: (args: Settings) => void;
}

const customerIdRegex =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
export default function SettingsDialog({
  showDialog,
  onClose,
  onChange,
}: Props) {
  const dashboardsHostOverride = useMemo(() => {
    const override = new URLSearchParams(window.location.search).get(
      "dashboardsHostOverride"
    );

    if (!!override) {
      return new URL(override);
    }

    return undefined;
  }, []);

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [environment, setEnvironment] = useState<Environment>(
    Constants.getDefaultEnvironment()
  );
  const [environmentName, setEnvironmentName] = useState<EnvironmentName>(
    Constants.getDefaultEnvironment().name
  );
  const [apiToken, setApiToken] = useState("");
  const [customerId, setCustomerId] = useState<string>("");
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [colorOverrides, setColorOverrides] = useState<Record<string, string>>(
    Constants.ColorDefaults
  );
  const [bmGroupKeyOverrides, setBMGroupKeyOverrides] = useState<string>("{}");
  const [showZeroUsageLineItems, setShowZeroUsageLineItems] = useState(false);
  const [hideGrantName, setHideGrantName] = useState(false);
  const [hideVoidedInvoices, setHideVoidedInvoices] = useState(false);
  const [customerIdOverride, setCustomerIdOverride] = useState("");
  const [iframeBackgroundColor, setIframeBackgroundColor] = useState<
    string | undefined
  >(undefined);
  const [clientPreset, setClientPreset] = useState("custom");

  function resetColors() {
    setIframeBackgroundColor(undefined);
    setColorOverrides(Constants.ColorDefaults);
    setDarkMode(false);
    setClientPreset("custom");
  }

  const [darkMode, setDarkMode] = useState(false);
  function toggleDarkMode() {
    if (!darkMode) {
      setColorOverrides(Constants.DarkModeColorDefaults);
    } else {
      setColorOverrides(Constants.ColorDefaults);
    }
    setClientPreset("custom");
    setDarkMode(!darkMode);
  }

  async function loadCustomers(
    accessToken: string,
    environment: Environment
  ): Promise<Customer[]> {
    const apiUrl = environment.apiUrl;
    const response = await fetch(apiUrl + "/customers?limit=99", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "content-type": "application/json",
      },
    });

    const { data } = await response.json();
    const customerData = data as Customer[];
    if (customerData) {
      setCustomers(customerData);
    }

    return customerData;
  }

  useEffect(() => {
    async function load() {
      try {
        if (apiToken) {
          // verify the token by requesting the Customers, if not valid, then don't store the token
          const currentCustomers = customers?.length
            ? customers
            : await loadCustomers(apiToken, environment);
          if (currentCustomers) {
            setCustomers(currentCustomers);
            const defaultCustomer =
              currentCustomers.find((c) => c.id === customerId) ||
              currentCustomers[0];
            setCustomerId(defaultCustomer?.id);
            setIsFirstLoad(false);
            if (customerId && isFirstLoad) {
              onChange({
                clientPreset,
                apiToken,
                isDarkMode: darkMode,
                iframeBackgroundColor,
                colorOverrides,
                customerId,
                environment,
                environmentName,
                dashboardsHostOverride,
                bmGroupKeyOverrides,
                dashboardOptions: [
                  {
                    key: "show_zero_usage_line_items",
                    value: showZeroUsageLineItems ? "true" : "false",
                  },
                  {
                    key: "hide_grant_name",
                    value: hideGrantName ? "true" : "false",
                  },
                  {
                    key: "hide_voided_invoices",
                    value: hideVoidedInvoices ? "true" : "false",
                  },
                ],
              });
            }
          } else {
            setCustomers([]);
            setCustomerId("");
          }
        }
      } catch {
        window.localStorage.removeItem("settings_v2");
        setApiToken("");
        setCustomers([]);
        setCustomerId("");
        resetColors();
      }
    }

    load();
  }, [
    customers,
    apiToken,
    onChange,
    environment,
    customerId,
    isFirstLoad,
    environmentName,
    dashboardsHostOverride,
    colorOverrides,
    showZeroUsageLineItems,
    hideGrantName,
    hideVoidedInvoices,
    bmGroupKeyOverrides,
    clientPreset,
    darkMode,
    iframeBackgroundColor,
  ]);

  useEffect(() => {
    try {
      const settings = JSON.parse(
        window.localStorage.getItem("settings_v2") || "{}"
      ) as Settings;
      const localToken = settings.apiToken;
      const localEnvironment = settings.environmentName
        ? Constants.getEnvironment(settings.environmentName)
        : Constants.getDefaultEnvironment();

      setEnvironmentName(localEnvironment.name);
      setEnvironment(localEnvironment);
      setApiToken(localToken);
      setCustomerId(settings.customerId);
      const colorOverrideSettings = { ...Constants.ColorDefaults };
      Object.entries(settings.colorOverrides).forEach(([key, _value]) => {
        // override from settings
        const override = settings.colorOverrides[key];
        if (override) {
          colorOverrideSettings[key] = override;
        }
      });

      setClientPreset(settings.clientPreset ?? "custom");
      setIframeBackgroundColor(settings.iframeBackgroundColor);
      setDarkMode(settings.isDarkMode);
      setColorOverrides(colorOverrideSettings);
      setBMGroupKeyOverrides(settings.bmGroupKeyOverrides ?? "{}");

      const background = document.getElementById("contentContainer");
      if (settings.iframeBackgroundColor) {
        if (background)
          background.style.backgroundColor = settings.iframeBackgroundColor;
      } else {
        if (settings.isDarkMode) {
          if (background) background.style.backgroundColor = "black";
        } else {
          if (background) background.style.backgroundColor = "white";
        }
      }
    } catch {
      window.localStorage.removeItem("settings_v2");
      setApiToken("");
    }
  }, []);

  return (
    <Drawer open={showDialog} onClose={onClose} backdrop="static">
      <Drawer.Header>
        <Drawer.Title>Settings</Drawer.Title>
        <Drawer.Actions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            type="submit"
            disabled={apiToken?.length < 10 || !customerId}
            onClick={() => {
              const finalCustomerId =
                customerIdOverride.length > 0 ? customerIdOverride : customerId;
              window.localStorage.setItem(
                "settings_v2",
                JSON.stringify({
                  apiToken,
                  customerId: finalCustomerId,
                  colorOverrides,
                  dashboardsHostOverride,
                  environmentName,
                  bmGroupKeyOverrides,
                  isDarkMode: darkMode,
                  clientPreset,
                  iframeBackgroundColor,
                })
              );
              onChange({
                clientPreset,
                iframeBackgroundColor,
                apiToken,
                customerId: finalCustomerId,
                colorOverrides,
                isDarkMode: darkMode,
                dashboardsHostOverride,
                environment,
                environmentName,
                bmGroupKeyOverrides,
                dashboardOptions: [
                  {
                    key: "show_zero_usage_line_items",
                    value: showZeroUsageLineItems ? "true" : "false",
                  },
                  {
                    key: "hide_grant_name",
                    value: hideGrantName ? "true" : "false",
                  },
                  {
                    key: "hide_voided_invoices",
                    value: hideVoidedInvoices ? "true" : "false",
                  },
                ],
              });

              const background = document.getElementById("contentContainer");
              if (iframeBackgroundColor) {
                if (background)
                  background.style.backgroundColor = iframeBackgroundColor;
              } else {
                if (darkMode) {
                  if (background) background.style.backgroundColor = "black";
                } else {
                  if (background) background.style.backgroundColor = "white";
                }
              }
              onClose();
            }}
          >
            Load Dashboard
          </Button>
        </Drawer.Actions>
      </Drawer.Header>
      <Drawer.Body>
        <div className="flex flex-col gap-3">
          <SelectPicker
            className="grow"
            searchable={false}
            data={Constants.environments.map(({ name }) => ({
              label: name,
              value: name,
            }))}
            value={environmentName}
            onChange={(env) => {
              const envName = env || EnvironmentName.DevWithStagingBackend;
              setEnvironmentName(envName);
              setApiToken("");
              setEnvironment(Constants.getEnvironment(envName));
              setCustomerId("");
              setCustomers([]);
            }}
          />
          <Input
            name="apiToken"
            className="grow"
            value={apiToken}
            placeholder="API token"
            onChange={setApiToken}
          />
          <SelectPicker
            disabled={customerIdRegex.test(customerIdOverride)}
            className="grow"
            data={customers.map(({ id, name }) => ({
              label: name,
              value: id,
            }))}
            value={customerId}
            onChange={(val) => {
              setCustomerId(val === null ? "" : val);
            }}
          />
          <div>
            <Input
              name="customerId"
              className="grow"
              value={customerIdOverride}
              placeholder="Customer ID"
              onChange={(val) => {
                setCustomerIdOverride(val);
                if (customerIdRegex.test(val)) {
                  setCustomerId(val);
                }
              }}
            />
          </div>
          <div>
            <Checkbox
              checked={showZeroUsageLineItems}
              onChange={(_val, checked) => {
                setShowZeroUsageLineItems(checked);
              }}
            >
              Show zero usage
            </Checkbox>
          </div>
          <div>
            <Checkbox
              checked={hideGrantName}
              onChange={(_val, checked) => {
                setHideGrantName(checked);
              }}
            >
              Hide grant name
            </Checkbox>
          </div>
          <div>
            <Checkbox
              checked={hideVoidedInvoices}
              onChange={(_val, checked) => {
                setHideVoidedInvoices(checked);
              }}
            >
              Hide voided invoices
            </Checkbox>
          </div>
          <div>
            <div className="text-lg mb-2">Override colors</div>
            <div className="flex flex-row gap-2 items-center">
              <Button onClick={resetColors}>Reset colors</Button>
              <SelectPicker
                className="grow"
                searchable={false}
                data={Constants.ClientDefaults}
                labelKey="name"
                valueKey="name"
                value={clientPreset}
                onChange={(val) => {
                  setClientPreset(val ?? "custom");
                  const client = Constants.ClientDefaults.find(
                    (c) => c.name === val
                  );
                  if (client) {
                    setColorOverrides(client.overrides);
                    setDarkMode(client.darkMode);
                    setIframeBackgroundColor(client.iframeBackgroundColor);
                  } else {
                    resetColors();
                  }
                }}
              />
            </div>
            <div className="mt-2">
              <Toggle
                className="text-lg mb-2"
                checked={darkMode}
                label=" Dark mode"
                onChange={toggleDarkMode}
              />
            </div>
          </div>
          <div className="flex flex-wrap justify-between">
            {Object.entries(
              darkMode
                ? Constants.DarkModeColorDefaults
                : Constants.ColorDefaults
            ).map(([name, defaultValue]) => {
              const value = colorOverrides[name] || defaultValue;
              return (
                <div className="flex flex-col gap-2 mb-4" key={`color_${name}`}>
                  <div>{name}</div>
                  <HexColorPicker
                    color={value}
                    onChange={(newColor) => {
                      const newColorOverrides = { ...colorOverrides };
                      newColorOverrides[name] = newColor;
                      setColorOverrides(newColorOverrides);
                      setClientPreset("custom");
                    }}
                  />

                  <HexColorInput
                    color={value}
                    onChange={(newColor) => {
                      const newColorOverrides = { ...colorOverrides };
                      newColorOverrides[name] = newColor;
                      setColorOverrides(newColorOverrides);
                      setClientPreset("custom");
                    }}
                  />
                </div>
              );
            })}
          </div>
          <div className="text-lg mb-2">Billable metric group key overrides</div>
            <textarea
              rows={10}
              placeholder="[]"
              value={bmGroupKeyOverrides}
              onChange={(e) => setBMGroupKeyOverrides(e.target.value)}
              className="w-full p-2 border rounded"
            />
          </div>
      </Drawer.Body>
    </Drawer>
  );
}
